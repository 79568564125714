.navbar {
  width: 60px;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: fixed;
  left: 0;
  padding-bottom: 16px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 2px;
    background: linear-gradient(0deg, #ceff1a 0%, rgba(206, 255, 26, 0) 80%);
  }

  a {
    color: $white;
    text-decoration: none;
    &:hover {
      color: $sky;
    }
    &:focus {
      outline: none;
      color: $lime;
    }
  }

  .navbar-home {
    padding-top: 32px;
    flex-grow: 1;
  }

  .navbar-sm {
    flex-grow: 1;
    a {
      padding: 4px 0;
    }
  }

  .navbar-sm,
  .navbar-links,
  .navbar-home {
    display: flex;
    flex-direction: column;
  }

  .navbar-sm,
  .navbar-home {
    align-items: center;
    font-size: 2rem;
  }

  .navbar-links {
    a {
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      padding: 18px 16px;
      font-size: 1.5rem;
      font-weight: 300;
      &:focus {
        outline: none;
        color: $lime;
      }
    }
  }
}
