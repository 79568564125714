.profil {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .profil-info {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: fit-content;

    .profil-hello {
      font-size: 6rem;
      font-weight: 700;
      margin-bottom: 48px;
    }

    .profil-headline {
      position: relative;

      .profil-name {
        transition: transform 0.8s;
      }

      .profil-name,
      .profil-title {
        backface-visibility: hidden;
      }

      .profil-title {
        transform: rotateX(180deg);
        position: absolute;
        top: 0;
        left: 0;
      }

      &:hover {
        .profil-name {
          transform: rotateX(180deg);
        }
        .profil-title {
          transform: rotateX(0deg);
          transition: transform 0.8s;
        }
      }
    }

    h1 {
      font-weight: 300;
      max-width: 600px;
      font-size: 6rem;
      font-family: "Montserrat", sans-serif;
      color: $sky;
      letter-spacing: 0.6rem;
    }
  }

  .profil-image {
    background: url("../../img/profil.jpg");
    background-size: cover;
    margin-left: 100px;
    width: 500px;
    height: 500px;
    box-shadow: 8px 8px $sky, 16px 16px $lime;
    transition: 0.3s;
    &:hover {
      box-shadow: 12px 12px $sky, 24px 24px $lime;
    }
  }
}
