@import "./config/colors.scss";
@import "./config/fonts.scss";

@import "./components/components.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

