.sm-links {
  width: 25%;
  padding: 0 4%;
  display: flex;
  justify-content: space-around;
  margin: -40px 0;
  a {
    font-size: 3rem;
    color: $white;
    &:hover {
      color: $sky;
    }
    &:focus {
      outline: none;
      color: $lime;
    }
  }
}

.popup-message {
  background-color: $sky;
  position: absolute;
  font-size: 1.3rem;
  padding: 12px 18px;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
}

.popup-message.show {
  opacity: 1;
  top: 48px;
  visibility: visible;
  transition: all 0.4s;
}

.contact-form {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .form-inputs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    label {
      width: 45%;
    }

    input {
      border-bottom: 2px solid $sky;
      padding: 0.3rem;
      width: 100%;

      &:focus {
        border-bottom: 2px solid $lime;
      }
    }
  }

  label {
    width: 100%;
    position: relative;

    .input-label,
    .textarea-label {
      position: absolute;
      color: white;
      opacity: 0.4;
      font-size: 1.3rem;
      font-family: "Lato";
      transition: 0.4s;
    }

    .input-label {
      top: 6px;
      left: 6px;
    }

    .textarea-label {
      top: 4px;
      left: 12px;
    }

    input,
    textarea {
      background-color: $background;
      border: none;
      color: $white;
      font-size: 1.3rem;
      font-family: "Lato";

      &:focus {
        outline: none;

        & + .input-label {
          opacity: 0.8;
          top: -18px;
          left: 4px;
          font-size: 1rem;
        }
      }

      &:valid {
        & + .input-label {
          opacity: 0.8;
          top: -18px;
          left: 4px;
          font-size: 1rem;
        }
      }
    }

    textarea {
      width: 100%;
      margin-bottom: 32px;
      border-left: 2px solid $sky;
      padding: 1.3rem 0.6rem 0;

      &:focus {
        border-left: 2px solid $lime;

        &::-webkit-resizer {
          border-bottom: 2px solid $lime;
          border-right: 2px solid $lime;
        }

        & + .textarea-label {
          opacity: 0.8;
          top: -4px;
          left: 10px;
          font-size: 1rem;
        }
      }

      &:valid {
        & + .textarea-label {
          opacity: 0.8;
          top: -4px;
          left: 10px;
          font-size: 1rem;
        }
      }
    }
  }

  ::-webkit-resizer {
    border-bottom: 2px solid $sky;
    border-right: 2px solid $sky;
  }
}
