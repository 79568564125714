main {
  background-color: $background;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: $white;
  display: flex;
  justify-content: center;
  min-height: 100vh;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .container {
      display: flex;
      flex-direction: column;
      height: 100%;
      // width: calc(100vw - 60px);
      justify-content: flex-start;
      align-items: center;
      margin-top: 20vh;
    }

    h1 {
      font-size: 2.5rem;
      letter-spacing: 0.25rem;
    }

    .divider {
      border: 1px solid $lime;
      opacity: 0.6;
      width: 25%;
      margin: 80px 0;
    }

    button {
      background-color: $sky;
      width: fit-content;
      border: none;
      color: $white;
      font-weight: 500;
      letter-spacing: 0.2rem;
      font-family: "Montserrat";
      font-size: 1.5rem;
      padding: 0.75rem 1.5rem;
      transition: 0.3s;
      &:hover,
      &:focus {
        cursor: pointer;
        outline: none;
        box-shadow: -6px 6px $lime;
      }
    }
  }
}

a {
  &:focus {
    outline: none;
    color: $lime;
  }
}

.inline-link {
  color: $sky;
  text-decoration: none;
  position: relative;

  &:hover {
    color: white;
    &::before,
    &::after {
      content: " ";
      bottom: 0;
      position: absolute;
      width: 100%;
      height: 40%;
    }

    &::before {
      z-index: -1;
      left: -3px;
      bottom: 2px;
      background-color: $sky;
      // border-bottom: solid 1rem $lime;
      box-shadow: -2px 2px $lime;
    }

    // &::after {
    //   z-index: -1;
    //   left: -2px;
    //   border-bottom: solid 1rem $sky;
    // }
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $background;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $lime;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $sky;
}
