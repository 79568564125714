.footer {
  align-self: center;
  padding: 32px 0;
  font-size: 1.3rem;
  font-weight: 300;
  flex-grow: 0;
  margin-top: 48px;

  .footer-heart,
  .footer-copyright {
    margin: 0 4px;
    margin-bottom: -2px;
  }

  .footer-heart {
    font-size: 1.1rem;
    color: $sky;
  }

  .footer-copyright {
    color: $lime;
    font-size: 1.2rem;
  }
}
