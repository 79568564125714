.about-intro {
  width: 720px;
  margin-left: 225px;

  .about-intro-image {
    background: url("../../img/instagram-starwars.jpg");
    background-size: cover;
    float: left;
    margin-left: -225px;
    margin-right: 32px;
    margin-bottom: 32px;
    width: 450px;
    height: 450px;
    box-shadow: -8px 8px $sky, -16px 16px $lime;
    transition: 0.3s;
    &:hover {
      box-shadow: -12px 12px $sky, -24px 24px $lime;
    }
  }

  .about-intro-text {
    margin-top: 32px;

    p {
      min-width: 500px;
      opacity: 0.8;
      margin-top: 16px;
      font-size: 1.3rem;
      font-weight: 300;
      word-spacing: 0.2rem;
      text-align: justify;
    }
  }
}

.hobbies {
  width: 900px;

  .hobbies-title {
    margin-bottom: 48px;
  }
}

.hobby-entry-left,
.hobby-entry-right {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  min-height: 350px;

  .hobby-image-right,
  .hobby-image-left {
    width: 350px;
  }

  .hobby-name {
    margin-bottom: 16px;
  }

  .hobby-text {
    opacity: 0.8;
    font-size: 1.3rem;
    font-weight: 300;
    word-spacing: 0.2rem;
    text-align: justify;
  }

  // .hobby-button{
  //   color: white;

  // }
}

.hobby-entry-right {
  padding-right: 16px;
  border-right: solid 2px $lime;

  .hobby-image-right {
    float: right;
    margin-left: 24px;
  }

  // .hobby-buttons-right {

  // }
}

.hobby-entry-left {
  padding-left: 16px;
  border-left: solid 2px $lime;

  .hobby-image-left {
    float: left;
    margin-right: 24px;
  }
}
